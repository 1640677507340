import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";

const Verify = () => {
	const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState(null);
	const [success, setSuccess] = useState(false);

	const { search } = useLocation();
	const params = new URLSearchParams(search);

	let key = params.get("k");
	let id = params.get("i");

	useEffect(() => {
		(async () => {
			if (key && id) {
				let response = await fetch("/api/email/verify", {
					method: "POST",
					headers: {
						"content-type": "application/json"
					},
					body: JSON.stringify({
						key,
						id
					})
				});

				try {
					let body = await response.json();

					if (body.message)
						setMessage(body.message);
					else
						setMessage("An unknown error occurred");

					if (response.ok)
						setSuccess(true);
				} catch (_) {
					setMessage("An unknown error occurred");
				}
			}

			setLoading(false);
		})();
	}, [key, id]);

	if (loading) {
		return (
			<div className="flex items-center justify-center text-white flex-grow flex-col h-full">
				<svg className="animate-spin h-12 w-12 text-sky-500" fill="none" viewBox="0 0 24 24">
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
					<path
						className="opacity-75" fill="currentColor"
						d={`
							M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962
							7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z
						`}
					/>
				</svg>
			</div>
		);
	}

	if (!key || !id) {
		return (
			<div className="flex items-center justify-center text-white flex-grow flex-col">
				<div className="mb-12 mt-12 w-full">
					<h1 className="font-extrabold text-4xl mb-12">
						CompBot Gateway
					</h1>
					<XCircleIcon className="inline-block w-16 h-16 text-red-500 mb-2" />
					<h3 className="font-extrabold text-2xl text-red-500 mb-2">
						Missing parameters
					</h3>
					<h3 className="font-extrabold text-xl text-red-500">
						Ensure you copied the full URL from the verification email
					</h3>
				</div>
			</div>
		);
	}

	return (
		<div className="flex items-center justify-center text-white flex-grow flex-col">
			<div className="mb-12 mt-12 w-full">
				<img src="/logo.png" alt="logo" className="w-24 h-24 mb-4 inline-block" />
				<h1 className="font-extrabold text-4xl mb-12">
					CompBot Gateway
				</h1>
				{success ? (
					<>
						<CheckCircleIcon className="inline-block w-16 h-16 text-green-500 mb-2" />
						<h3 className="font-extrabold text-2xl text-green-500 mb-2">
							Verification Successful
						</h3>
						<h3 className="font-extrabold text-xl text-green-500">
							{message}
						</h3>
					</>
				) : (
					<>
						<XCircleIcon className="inline-block w-16 h-16 text-red-500 mb-2" />
						<h3 className="font-extrabold text-2xl text-red-500 mb-2">
							Verification Failed
						</h3>
						<h3 className="font-extrabold text-xl text-red-500">
							{message}
						</h3>
					</>
				)}
			</div>
		</div>
	);
};

export default Verify;
