import React, { useEffect, useRef, useState } from "react";
import Error from "../components/Error";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";

const Manage = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [user, setUser] = useState(null);
	const [membersLoading, setMembersLoading] = useState(false);
	const [membersError, setMembersError] = useState(false);
	const [membersSuccess, setMembersSuccess] = useState(false);

	const fileRef = useRef(null);

	const handleUpdateMembers = async () => {
		let file = fileRef.current.files[0];

		if (!file) {
			setMembersError("Please provide a file");
			return;
		}

		setMembersError(null);
		setMembersSuccess(null);
		setMembersLoading(true);

		let buffer = await file.arrayBuffer();

		try {
			let response = await fetch("/api/manage/members", {
				method: "POST",
				headers: {
					"content-type": "text/csv"
				},
				body: buffer
			});

			let body = await response.json();

			if (response.ok)
				setMembersSuccess(body.message);
			else
				setMembersError(body.message);

			setMembersLoading(false);
		} catch (err) {
			setMembersLoading(false);
			setMembersError("An unknown error occurred");
		}
	};

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);

				let response = await fetch("/api/user");
				let body = await response.json();

				if (response.ok)
					setUser(body.user);
				else if (response.status !== 401)
					setError(response.status);

				setLoading(false);
			} catch (_) {
				setError(500);
				setLoading(false);
			}
		})();
	}, []);

	if (loading) {
		return (
			<div className="flex items-center justify-center text-white flex-grow flex-col h-full">
				<svg className="animate-spin h-12 w-12 text-sky-500" fill="none" viewBox="0 0 24 24">
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
					<path
						className="opacity-75" fill="currentColor"
						d={`
							M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962
							7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z
						`}
					/>
				</svg>
			</div>
		);
	}

	if (error) {
		return (
			<Error statusCode={error} />
		);
	}

	if (!user) {
		return (
			<Error statusCode={401} />
		);
	}

	return (
		<>
			{user.admin ? (
				<div className="flex items-center justify-center text-white flex-grow flex-col">
					<div className="mb-12 mt-12 w-full">
						<img src="/logo.png" alt="logo" className="w-24 h-24 mb-4 inline-block" />
						<h1 className="font-extrabold text-4xl mb-4">
							CompBot Gateway &bull; Management
						</h1>
						<div className="flex flex-col items-center">
							<p className="text-lg font-bold mb-6">
								Upload a CSV export from Engage to update the member list
							</p>
							<input
								type="file"
								accept="text/csv"
								className={`
									bg-midnight mb-4 p-1 px-2 focus:border-sky-400
									focus:outline-none border-sky-500 border-2 rounded-lg w-full max-w-lg
								`}
								ref={fileRef}
								disabled={membersLoading}
							/>
							<button
								className={`
								bg-sky-500 rounded-lg w-full max-w-lg px-4 disabled:bg-sky-300
								p-2 font-extrabold text-white disabled:cursor-not-allowed mb-4 hover:bg-sky-600
							`}
								onClick={handleUpdateMembers} disabled={membersLoading}
							>
								Update Member List
							</button>
							<div className={`
							${membersError ? "block" : "hidden"} bg-red-500 w-full max-w-lg
							text-left text-white font-bold rounded-lg p-2 px-4 flex items-center`
							}>
								<ExclamationCircleIcon className="w-6 w-6 flex-shrink-0"/>
								<span className="ml-2">{membersError}</span>
							</div>
							<div className={
								`${membersSuccess ? "block" : "hidden"} bg-green-500 w-full max-w-lg
							text-left text-white font-bold rounded-lg p-2 px-4 flex items-center`
							}>
								<CheckCircleIcon className="w-6 w-6 flex-shrink-0"/>
								<span className="ml-2">{membersSuccess}</span>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Error statusCode={401} />
			)}
		</>
	);
};

export default Manage;
