import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import Error from "./components/Error";
import Landing from "./pages/Landing";
import Verify from "./pages/Verify";
import Manage from "./pages/Manage";

const App = () => {
	return (
		<div className="p-6 sm:p-3 select-none text-center min-h-screen font-lg flex-col flex justify-between">
			<Router>
				<Switch>
					<Route path="/manage">
						<Manage />
					</Route>
					<Route path="/verify">
						<Verify />
					</Route>
					<Route exact path="/">
						<Landing />
					</Route>
					<Route>
						<Error statusCode={404} />
					</Route>
				</Switch>
			</Router>
			<div className="font-bold text-white pb-8">
				<a
					className="text-sky-500 hover:text-sky-600"
					href="https://gitlab.com/honour/compbot"
					target="_blank" rel="noreferrer"
				>
					View the Source
				</a>
			</div>
		</div>
	);
};

export default App;
