import React, { useEffect, useRef, useState } from "react";
import Error from "../components/Error";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";

const Landing = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [user, setUser] = useState(null);
	const [emailLoading, setEmailLoading] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [emailSuccess, setEmailSuccess] = useState(false);

	const emailRef = useRef(null);

	const handleSendEmail = async () => {
		let email = emailRef.current.value;

		if (!email) {
			setEmailError("Please enter an email address");
			return;
		}

		setEmailError(null);
		setEmailSuccess(null);
		setEmailLoading(true);

		try {
			let response = await fetch("/api/email/send", {
				method: "POST",
				headers: {
					"content-type": "application/json"
				},
				body: JSON.stringify({
					email: email
				})
			});

			let body = await response.json();

			if (response.ok)
				setEmailSuccess(body.message);
			else
				setEmailError(body.message);

			setEmailLoading(false);
		} catch (err) {
			setEmailLoading(false);
			setEmailError("An unknown error occurred");
		}
	};

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);

				let response = await fetch("/api/user");
				let body = await response.json();

				if (response.ok)
					setUser(body.user);
				else if (response.status !== 401)
					setError(response.status);

				setLoading(false);
			} catch (_) {
				setError(500);
				setLoading(false);
			}
		})();
	}, []);

	if (loading) {
		return (
			<div className="flex items-center justify-center text-white flex-grow flex-col h-full">
				<svg className="animate-spin h-12 w-12 text-sky-500" fill="none" viewBox="0 0 24 24">
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
					<path
						className="opacity-75" fill="currentColor"
						d={`
							M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962
							7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z
						`}
					/>
				</svg>
			</div>
		);
	}

	if (error) {
		return (
			<Error statusCode={error} />
		);
	}

	return (
		<div className="flex items-center justify-center text-white flex-grow flex-col">
			<div className="mb-12 mt-12 w-full">
				<img src="/logo.png" alt="logo" className="w-24 h-24 mb-4 inline-block" />
				<h1 className="font-extrabold text-4xl mb-4">
					CompBot Gateway
				</h1>
				{user ? (
					<div className="flex flex-col items-center">
						<button className="bg-sky-500 rounded-lg mb-2 w-full max-w-lg px-4 p-2 font-extrabold text-white">
							Logged in as {user.username}
						</button>
						<a href="/api/logout" className="font-bold text-sm text-sky-500 mb-6">
							Wrong account? Logout
						</a>
						<p className="text-lg font-bold">
							Enter the email you use to log into Engage below.
						</p>
						<p className="text-lg font-bold mb-6">
							For most people, this will be your university email address.
						</p>
						<input
							type="email"
							className={`
								bg-midnight mb-4 p-1 px-2 focus:border-sky-400
								focus:outline-none border-sky-500 border-2 rounded-lg w-full max-w-lg
							`}
							ref={emailRef}
							disabled={emailLoading}
							placeholder="Email Address"
						/>
						<button
							className={`
								bg-sky-500 rounded-lg w-full max-w-lg px-4 disabled:bg-sky-300
								p-2 font-extrabold text-white disabled:cursor-not-allowed mb-4 hover:bg-sky-600
							`}
							onClick={handleSendEmail} disabled={emailLoading}
						>
							Send Verification Email
						</button>
						<div className={`
							${emailError ? "block" : "hidden"} bg-red-500 w-full max-w-lg
							text-left text-white font-bold rounded-lg p-2 px-4 flex items-center`
						}>
							<ExclamationCircleIcon className="w-6 w-6 flex-shrink-0"/>
							<span className="ml-2">{emailError}</span>
						</div>
						<div className={
							`${emailSuccess ? "block" : "hidden"} bg-green-500 w-full max-w-lg
							text-left text-white font-bold rounded-lg p-2 px-4 flex items-center`
						}>
							<CheckCircleIcon className="w-6 w-6 flex-shrink-0"/>
							<span className="ml-2">{emailSuccess}</span>
						</div>
					</div>
				) : (
					<>
						<p className="text-lg font-bold">
							To gain access to the CompSoc Discord Server, login with Discord below.
						</p>
						<p className="text-lg font-bold mb-6">
							You&apos;ll then be prompted to enter your LUU email address so we can verify you&apos;re a member.
						</p>
						<div className="flex flex-col items-center">
							<a
								className="bg-sky-500 hover:bg-sky-600 rounded-lg w-full max-w-lg px-4 p-2 font-extrabold text-white"
								href="/api/discord/login"
							>
								Login with Discord
							</a>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Landing;
